import React from 'react';

const Button = ({ onClick, children, disabled, className, type = "button" }) => {
    return (
        <button
            type={type}
            className={`rounded-btn text-[12px] md:text-[20px]  flex justify-center tracking-[2px] md:tracking-[10px] ${className}`}
            onClick={onClick}
            disabled={disabled}
        >
            <span className='max-w-10/12 md:-mr-[10px]'>
                {children}
            </span>
        </button>
    );
};

export default Button;

