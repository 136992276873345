import React from 'react';
import SpotifyLogin from './spotify/login';
import Button from './components/Button';

function Login() {
  const handleLogin = () => {
    SpotifyLogin.logInWithSpotify();
  }

  return (

    <Button className={'xl mx-auto'} onClick={handleLogin}>
      Log in with Spotify
    </Button>

  );
}

export default Login;
