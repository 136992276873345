import React from 'react';
import Facebook from '../assets/social/facebook.svg'
import Twitter from '../assets/social/twitter.svg'
import Instagram from '../assets/social/instagram.svg'
import Shopping from '../assets/social/shopping.svg'
import Help from '../assets/social/help.svg'
import Tiktok from '../assets/social/tiktok.svg'
import { Link } from 'react-router-dom';

const socialIcons = [{
    component: Facebook,
    url: 'https://www.facebook.com/djannabr/'
},
{
    component: Instagram,
    url: 'https://www.instagram.com/djannaofficial/'
},
{
    component: Twitter,
    url: 'https://twitter.com/djannamiranda'
}, {
    component: Shopping,
    url: 'https://anna.dj/shop'
}, {
    component: Tiktok,
    url: 'https://www.tiktok.com/@djannaofficial'
}]

// {
//     component: Help,
//     url: 'https://www.facebook.com/djannabr/'
// }

const SocialLinks = () => {
    return (<div className='flex absolute bottom-[20%] md:bottom-0 left-1/2 -translate-x-1/2 md:translate-x-0 justify-center md:justify-start w-full md:w-auto gap-2 mb-30 md:mb-0 md:gap-0  md:left-0'>
        {socialIcons.map(({ component, url },index) => {
            return (<a key={`social-${index}`} href={url}>
                <img src={component} className='w-8 md:w-10 button-ui active' />
            </a>)
        })}
        <Link to={`/`}>
            <img src={Help} className='w-8 md:w-10 button-ui active' />
        </Link>
    </div>);
};

export default SocialLinks;

