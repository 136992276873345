import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import content from '../settings';

import SharePage from '../SharePage';
import Message from '../Message';
import Share from '../assets/share.svg';
import Back from '../assets/back.svg';
import Close from '../assets/close.svg';
import Pause from '../assets/pause.svg';
import Play from '../assets/play.svg';
import FullscreenVideoPlayer from '../FullScreenVideoPlayer';



const PlayScreen = ({ token, setFading, player }) => {
  const { songId } = useParams();



  const location = useLocation();
  const shareInUrl = location.pathname.includes('/share');

  const navigate = useNavigate();

  const returnToHome = () => {
    setFading(true);
    player.setEndSong(true);
    setTimeout(() => {
      setFading(false);
      navigate('/menu'); // Navigates to the home page
    }, (500));
  }

  // const handlePlaySong = (playFunction) => {
  //   setPlaySong(() => playFunction); // using a function to update to avoid stale closures
  // }

  useEffect(() => {
    let item = content.find(item => item.spotifyURI === songId);
    item = { ...item, timestamp: Date.now() }
    player.setSongItem(item);
  }, [songId]);



  return (
    <div>
      {player.songItem && (
        <>
          <div className="video-container  louize-display">
            <FullscreenVideoPlayer finishedLoading={() => { setFading(false) }} src={`https://stream.mux.com/${player.songItem.video}/high.mp4`} />
            <div className='fixed h-full z-10 w-6/12 left-1/4 mx-auto blur-[200px] bg-black opacity-80' />
            <div className="relative flex justify-between z-20 pointer-events-auto">
              <img draggable="false" onClick={returnToHome} alt='back' className='w-10 md:w-14 m-2 button-ui active' src={Back} />
              <img draggable="false" className={`w-10 md:w-14 m-2 button-ui ${player.playPause ? 'active' : ''}`} onClick={() => {
                player.togglePlay(!player.toggle);
              }} src={player.paused ? Play : Pause} />

              <Link to={`/song/${player.songItem.spotifyURI}${shareInUrl ? '' : '/share'}`}>
                <img draggable="false" className='w-10 md:w-14 m-2 button-ui active' src={shareInUrl ? Close : Share} />
              </Link>
            </div>

            <div className='overflow-x-hidden relative z-10 tracking-wide w-full flex md:items-start justify-center h-screen'>
              <div className='mb-40 mt-5 max-w-[90%] lg:max-w-2xl   h-min'>
                <Message returnToHome={returnToHome} song={player.songItem} setFading={setFading} />
              </div>
            </div>
          </div>

          <div>

          </div>




        </>)}


    </div>
  );
}

// playSong(songItem.spotifyURI);
// ${appIntro ? 'inactive' : 'active'}

export default PlayScreen;
