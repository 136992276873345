import React, { useState, useEffect, useRef } from 'react';
import content from './settings';
import Button from './components/Button';
import { useNavigate } from 'react-router-dom';
const web_url = process.env.REACT_APP_REDIRECT_ID;

const SharePage = ({ song,setPrompt, prompt, returnToHome, setFading }) => {

  const [hasRendered, setHasRendered] = useState(false);
  const navigate = useNavigate()

  useEffect(() => {
    drawOnCanvas().then(() => setHasRendered(true)); // sets hasRendered to true after the Promise resolves
  }, []);

  const changePage = (uri) => {
    setFading(true);
    setPrompt([])
    const timer = setTimeout(() => {
      navigate(`/song/${uri}`);
    }, 500);
  }

  const canvasRef = useRef(null);

  const drawOnCanvas = () => {
    return new Promise((resolve) => {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const image = new Image();
      image.crossOrigin = 'anonymous'; // For CORS issues when loading images from external sources
      image.src = `/covers/${song.cover}`; // Provide the URL or local path of your image

      image.onload = () => {
        // Draw the image on the canvas
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);


        // Set up the drop shadow
        ctx.shadowColor = 'rgba(0,0,0,.5)';
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 2;
        ctx.shadowOffsetY = 2;


        // Set up the text properties
        ctx.font = '50px louize-display';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';

        // Define your words
        const words = prompt.length > 0 ? prompt : ['Intentions']

        // Draw each word on the canvas
        for (let i = 0; i < words.length; i++) {
          wrapText(ctx, words[i].toUpperCase(), canvas.width / 2, (canvas.height / (words.length + 1)) * (i + 1), canvas.width, 50);
        }

        resolve();
      };
    });
  };

  // Function to wrap text
  function wrapText(context, text, x, y, maxWidth, lineHeight) {
    var words = text.split(' ');
    var line = '';

    for (var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + ' ';
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if (testWidth > maxWidth && n > 0) {
        context.fillText(line, x, y);
        line = words[n] + ' ';
        y += lineHeight;
      }
      else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
  }

  const getImageUrl = async () => {
    const canvas = canvasRef.current;
    return canvas.toDataURL('image/jpeg')
  };



  const downloadImage = async () => {
    const imageUrl = await getImageUrl();
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'generated-image.png';
    link.click();
  };


  const index = content.findIndex(item => item.spotifyURI === song.spotifyURI);
  const nextItem = content[index + 1];  // This will be undefined if there is no next item


  return (
    <div className='text-[12px] md:text-[25px]'>
      <div className=' text-center my-4 text-shadow louize-display'>Download then share your intentions on socials <br /> #intentionsbyanna</div>
      <div className='bg-[rgba(255,255,255,0.2)] my-2 mt:my-10 w-9/12 mx-auto'>
        <canvas className={`w-full fade ${hasRendered ? 'instant-active' : ''}`} ref={canvasRef} width={500} height={500}></canvas>
      </div>
      <div className=' mt-5 md:mt-10 flex justify-center items-center  flex-col'>

        <Button onClick={downloadImage} className="mt-4 xl">
          Download
        </Button>

      </div>
      <div className='flex gap-2 justify-around'>

        <Button onClick={returnToHome} className="md:mt-10">
          Home
        </Button>

        {nextItem && (

          <Button onClick={() => changePage(nextItem.spotifyURI)} className="md:mt-10">
            Next
          </Button>

        )}
      </div>

    </div>
  );
};

export default SharePage;
