import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import content from '../settings';
import SocialLinks from '../components/SocialLinks';


const Menu = ({setFading}) => {
  const navigate = useNavigate();
  const [active, setActive] = useState(true);

  const changePage = (index) => {
    setActive(false);
    setFading(true);
    const timer = setTimeout(() => {
      navigate(`/song/${content[index].spotifyURI}`);
    }, 500);

    return () => clearTimeout(timer); // Clean up the timeout on component unmount

  }


  return (<>
  <div className='w-full bg-[rgba(0,0,0,0.5)]'>
    <div className='overflow-x-hidden  louize-bold leading-none	 text-shadow text-[20px] md:text-[30pt]  -right-[0.025em] w-11/12  mx-auto flex md:items-center justify-center h-screen'>
      <div className='mt min-h-[600px] w-full h-full'>
        <div className='menu-buttons  gap-4 md:gap-8'>
          {content.map((item, index) => (
            <div className={`blur-ui ${active ? 'active' : ''} tracking-widest  relative `} key={index} onClick={() => changePage(index)}>
              <span>{item.name}</span>
            </div>
          ))}
        </div>
      </div>
      <SocialLinks/>
    </div>
    </div>
    </>
  );
}

export default Menu;
