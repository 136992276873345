const content = [
  {
    name: 'INVITATION',
    'spotifyURI': '55PWPOpQwzXd2hxlFohLDP',
    'video': '7KD01eDvTrE1bKp01wuAsEK5201H4zgAz3Espy4pNdL47w',
    'cover': 'invitation.jpg',
    'songClass':'w-2/3',
    copy: [{
      type: 'text-center',
      message: `In this moment, take a pause.`
    }, {
      type: ' md:max-w-[500px] mx-auto',
      message: `Yield to the stillness that anchors your mind. 
      Only this.  An interlude that asks nothing
      of you except to sit and breathe.`
    }, {
      type: ' mx-auto text-center mt-2 md:mt-5 ',
      message: `Rest here, in your own awareness. 
      Relax. Trust. Connect. Go within;
      the place where magic dwells. `
    }, {
      type: ' mx-auto mt-2 md:mt-5  md:max-w-[500px]',
      message: `Let this invitation wrap you up in presence and provide a space for insight.`
    }, {
      type: ' mx-auto normal mt-2 md:mt-5',
      message: `Set an intention to...`
    }, {
      type: 'center indent-4 md:indent-16',
      message: `slow down, take a pause, go within.`
    }]
  },
  {
    name: 'RECEIVING',
    'spotifyURI': '2G1GDfDZtPMAOH2JXC3t1r',
    'video': 'DSMx8vq19Vh00vmwEYRCO5T5hMaGAOcHlRiirx2p8wao',
    'cover': 'receiving.jpg',
    'songClass':'',
    copy: [{
      type: 'text-center mt-2 md:text-left md:indent-20',
      message: `Let it go. Let it be. Let it arrive.`
    },{
      type: 'text-center md:text-left',
      message: `Just like it’s meant to. Release the need to 
      try, and allow whatever comes to flow in.`
    }, {
      type: 'mt-2 md:mt-5 max-w-[90%] md:max-w-[400px] mx-auto',
      message: `Receiving the totality of your 
      experience, without controlling it. 
      No trying. No resisting. 
      Only openness. `
    }, {
      type: 'mt-8 md:mt-5',
      message: `Maybe you will encounter bliss or find 
      deep resolution. Other times, nothing at all.
       Simply surrender; everything belongs here. 
       This song will hold the space for you to 
      receive. Trust it.`
    }, {
      type: 'normal mt-2 md:mt-5',
      message: `Set an intention to...`
    }, {
      type: 'mt-2 max-w-[80%] md:max-w-[400px] mx-auto',
      message: `surrender to what is and 
      welcome what appears.`
    }]
  },
  {
    name: "IT'S ALL YOU", 'spotifyURI': '2uxhrTWMXWJ9MfEUfChSQ3', 'video': 'U57eVuCJJLSV3btXYaw1MmFNiH4tMdAKy9fFucEmkIg', cover: 'its-all-you.jpg',
    'songClass':'w-2/3', 
    copy: [{
      type: 'max-w-[90%] mx-auto  indent-5 md:indent-10',
      message: `This is an offering for the universal power of Love. My intention is to channel the vibration of spirit through this collection of frequencies.`
    }, {
      type: 'max-w-[75%] md:max-w-[500px] mx-auto text-center mt-2 md:mt-5',
      message: `God, energy, source, divinity; \n Oneness, whatever speaks to you.`
    }, {
      type: 'normal mt-2 md:mt-5',
      message: `Every chord and loop echoing through the sky, the sea, the trees, your eyes. This song holds the frequency of  connection, of remembrance, a portal that connects infinity with the self.`
    }, {
      type: 'text-center mt-2 md:mt-5',
      message: `You are everything. It's all you.`
    }, {
      type: 'normal mt-2 md:mt-5',
      message: `I hope you feel it. It is my deepest wish that this music helps you to remember the infinite love that you already are.`
    }, {
      type: 'max-w-[75%] md:max-w-[500px] mx-auto text-center normal mt-2 md:mt-5',
      message: `Set an intention to...`
    }, {
      type: 'max-w-[90%] md:max-w-[500px] mx-auto mt-2',
      message: `lean into the vibration of spirit, to connect with that part of yourself that you share with the everything  and everyone.`
    }]
  },
  {
    name: 'WASHING AWAY', 'spotifyURI': '7fbs5eMww1t9TqHSkqs2XY', 'video': 'PwMCzhaz5CwZEqwz9tSSDvBppUnH6ma1hs02RCAOpH00M', cover: 'washing-away.jpg', 
    'songClass':'w-2/3',
    copy: [{
      type: 'indent-10',
      message: `Life is in motion. 
    But sometimes, stagnancy can stop us from being in flow. Let the frequencies in this track untether you from lower states; feel it washing away any heaviness that weighs you down.`
    }, {
      type: 'max-w-[400px] w-[90%] md:w-full mx-auto mt-2 md:mt-5',
      message: `Head outside, look at the sky, feel
       the rain or wind on your skin.`
    }, {
      type: 'mt-2 md:mt-5 mx-auto',
      message: `Place an intention in nature to let go of all that no longer serves you. Speak it internally or openly; your willingness to release any heaviness is enough.`
    }, {
      type: 'text-center mt-2 md:mt-5',
      message: `Let this music support your process.`
    }, {
      type: 'normal mt-2 md:mt-5',
      message: `Set an intention to...`
    }, {
      type: 'indent-4 md:indent-20 mt-2 mx-auto',
      message: `release all that no longer serves you.`
    }]
  },
  {
    name: 'I SEE MIRACLES EVERYWHERE', 'spotifyURI': '6PYazhU8WHEsQLiBr4xUgt', 'video': '8SMQWH1J00rKDrrxFAezrgjMUsZN5iN018dqviY7fM02DY', 'cover': 'i-see-miracles-everywhere.jpg',
    'songClass':'w-3/4',
    copy: [{
      type: 'indent-5',
      message: `By channelling these sound frequencies, my intention is to translate felt states of elevated emotion, of gratitude, joy and a connection to the divine, and expand that same frequency inside of you.`
    }, {
      type: 'max-w-[75%] md:max-w-[500px] mt-2 md:mt-5 mx-auto',
      message: `Miracles are all around us; in the giant golden ball that floats in the sky, in the wind that blows across the desert; in a woman’s body carrying new life.`
    }, {
      type: 'mt-2 md:mt-5 ',
      message: `I hope this music finds equal resonance inside you, and connects you to the deepest knowing:`
    }, {
      type: 'mt-2 md:mt-5 max-w-[75%] md:max-w-[500px] mx-auto ',
      message: `I see miracles everywhere.`
    }, {
      type: 'mt-2 md:mt-5',
      message: `Open your heart to what already exists. Let the alchemy of this sound help you see, feel and bear witness.`
    }, {
      type: 'normal mt-2 md:mt-5',
      message: `Set an intention to...`
    }, {
      type: 'max-w-[75%] md:max-w-[500px] mt-2 mx-auto',
      message: `open your eyes and heart to the miracles that exist within and beyond you.`
    }]
  },
  {
    name: 'AYNI', 'spotifyURI': '5hEKWB3gpTwuthWjT1Q47B', 'video': 'WHkzrycW2oUHgVTTtqcvT01DTT00L0100H5Q4hBR7qHvBVM', cover: 'ayni.jpg',
    'songClass':'',
     copy: [{
      type: 'indent-5',
      message: `I am forever inspired by the Inca principle of Ayni, which means “to be in right relationship” with self, with other, with the natural world around us.`
    }, {
      type: 'max-w-[400px] w-1/2 md:w-full mt-2 md:mt-5 mx-auto',
      message: `This track is a reminder: when we consciously seek to live in harmony and integrity, everything starts to align.`
    }, {
      type: 'mt-2 md:mt-5 ',
      message: `To live in reciprocity with your heart, with your being, with your neighbours, you navigate the world lightly and peacefully, meeting its many ups and downs with grace. To be in your centre in this way, synchronicities rush in.`
    }, {
      type: 'mt-2 md:mt-5 w-1/2 md:w-full max-w-[400px] mx-auto ',
      message: `Life carries you, even in the tough moments. You are held.`
    }, {
      type: 'mt-2 md:mt-5',
      message: `Close your eyes and listen to the intention flowing through Ayni. Feel yourself being in a harmonious exchange with life.`
    }, {
      type: 'normal mt-2 w-[60%] mx-auto md:w-full md:mt-5',
      message: `Set an intention to...`
    }, {
      type: 'max-w-[400px] w-1/2 md:w-full mt-2 mx-auto',
      message: `step into even greater affinity with self and other; life will flow.`
    }]
  },
  { name: 'LET YOU IN', 'spotifyURI': '2weK6bsvMtRaYWydEnMhfw', 'video': 'uO202Fd01lwz2Vy4dXEa00DAtlyCYru9ffJ6dT9YxJS4dY', cover: 'let-you-in.jpg',
  'songClass':'w-2/3',
  copy: [{
    type: 'indent-5',
    message: `What better excuse is there to celebrate, than to celebrate life itself? This track is a manifestation of joy, a call to dance across the landscape of your life, to acknowledge the events, experiences and relationships that nourish your soul.`
},{
    type: 'max-w-[75%] md:max-w-[500px] mt-2 md:mt-5 mx-auto',
    message: `I love to celebrate everything, and music is a tool to go inward, to connect deeper to undiscovered parts of yourself.`
},{
    type: 'mt-2 md:mt-5 ',
    message: `It is a vibrational gateway into a deep inner love that perhaps you didn’t know existed. Let love in. Let you in. Everything else will take care of itself.`
},{
    type: 'mt-2 md:mt-5',
    message: `Set an intention to...`
},{
    type: 'max-w-[75%] md:max-w-[500px] mt-2 mx-auto',
    message: `open up, let love in, and meet the 
    infinite inside of you. `
}] },
];

export default content;


//   https://anna-intentions.s3.eu-west-1.amazonaws.com/videos/



