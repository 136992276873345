export async function retry(fn, retriesLeft = 5, interval = 100) {
    try {
        return await fn();
    } catch (error) {
        if (retriesLeft) {
            console.log(`Retrying...${retriesLeft} attempts left`);
            setTimeout(() => retry(fn, retriesLeft - 1, interval), interval);
        } else {
            console.error('Max retries exceeded');
            throw error;
        }
    }
}