import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { retry } from './util';

let intervalId = null;
let volume = 0;

const track = {
    name: "",
    album: {
        images: [
            { url: "" }
        ]
    },
    artists: [
        { name: "" }
    ]
}

const WebPlayback = ({ token, songItem, endSong, setEndSong, paused, setPaused,toggle, setPlayPause }) => {

    const [ready, setReady] = useState(false);
    const [is_active, setActive] = useState(false);
    const [is_visible, setVisibility] = useState(false);
    const [current_track, setTrack] = useState(track);
    const playerRef = useRef(null);  // instead of const [player, setPlayer] = useState(undefined);
    const deviceIdRef = useRef('');
    const endSongTimeout = useRef(null);  // Add this line
    

    function setDeviceId(id) {
        deviceIdRef.current = id;
    }

    // function seeTrackOnSpotify() {
    //     window.location.href = `https://open.spotify.com/track/${current_track.id}`;
    // }

    // const handlePlaySong = (playFunction) => {
    //     // setPlaySong(() => playFunction); // using a function to update to avoid stale closures
    // }


    useEffect(() => {
        if(ready && songItem){
            setEndSong(false)
            playSong(songItem.spotifyURI)
        }
      }, [songItem, ready]);

      useEffect(() => {
        if(ready)
                playerRef.current.togglePlay();     
      }, [toggle]);
      
    useEffect(() => {
        if(endSong){
            handleSongPaused();
        }
      }, [endSong]);

      async function playSong(songId) {

        clearTimeout(endSongTimeout.current);
        let songURI = `spotify:track:${songId}`;
        fadeVolume(playerRef.current, 0.5, 8000);

        await retry(async () => {
            try {
                await axios.put(
                    `https://api.spotify.com/v1/me/player/play?device_id=${deviceIdRef.current}`,
                    { uris: [songURI], "position_ms": 0 },
                    {
                        headers: { Authorization: `Bearer ${token}` },
                    }
                );

            } catch (error) {
                console.error("Error playing specific song:", error);
                throw error; // Re-throw the error to be caught by retry
            }
        });
        setPaused(false);
        setActive(true);
        setPlayPause(true);
        setTimeout(() => {
            // setVisibility(true);
        }, 100);
    }

    const handleSongPaused = async (e) => {
        fadeVolume(playerRef.current, 0, 1000);
        setVisibility(false);
        endSongTimeout.current = setTimeout(() => {
            playerRef.current.pause()
            setActive(false);
        }, 2000);
    };

    const fadeVolume = (player, targetVolume, duration) => {
        let currentVolume = volume;
        const step = Math.abs(targetVolume - currentVolume) / (duration / 100);

        // Clear the existing interval if there is one
        if (intervalId !== null) {
            clearInterval(intervalId);
        }

        // Assign the new interval to the variable
        intervalId = setInterval(() => {
            if (targetVolume > currentVolume) {
                currentVolume += step;
                if (currentVolume > targetVolume) {
                    currentVolume = targetVolume;
                }
            } else {
                currentVolume -= step;
                if (currentVolume < targetVolume) {
                    currentVolume = targetVolume;
                }
            }

            if (currentVolume === targetVolume) {
                clearInterval(intervalId);
                intervalId = null;
            }

            volume = currentVolume;
            player.setVolume(currentVolume);
        }, 100);
    };




    useEffect(() => {

        const script = document.createElement("script");
        script.src = "https://sdk.scdn.co/spotify-player.js";
        script.async = true;


        document.body.appendChild(script);

        window.onSpotifyWebPlaybackSDKReady = () => {
            const player = new window.Spotify.Player({
                name: 'ANNA Web Experience',
                getOAuthToken: cb => { cb(token); },
                volume: 0
            });
            playerRef.current = player;





            // const handleSongClicked = async (e) => {

            //     await playSong(e.detail);
            //     fadeVolume(player, 0.5, 8000);
            // };



            player.addListener('ready', ({ device_id }) => {
                setDeviceId(device_id);
                setReady(true);
                // transferPlayback(device_id);
            });

            player.addListener('not_ready', ({ device_id }) => {
                console.log('Device ID has gone offline', device_id);
            });


            // document.addEventListener("song-clicked", handleSongClicked);
            document.addEventListener("song-paused", handleSongPaused);
            playerRef.current.addListener('player_state_changed', (state => {

                if (!state) {
                    return;
                }

                // if(state.track_window.current_track === null){
                //     return returnToHome();
                // }


                setTrack(state.track_window.current_track);
                setPaused(state.paused);

                playerRef.current.getCurrentState().then(state => {
                    (!state) ? setActive(false) : setActive(true)
                    setTimeout(() => {
                        (!state) ? setVisibility(false) : setVisibility(true)
                    }, 100)
                });

            }));

            player.connect();

        };
    }, []);

    // useEffect(() => {
    //     if (song) {
    //         playSong(song);
    //     }
    // }, [song]);

    // useEffect(() => {
    //     onPlaySong(playSong);
    // }, []);


    if (!is_active || current_track === null) {
        return <></>
    } else {
        return (
            <>
            </>
        );
    }
}

export default WebPlayback
