import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Prompt from './Prompt';
import SharePage from './SharePage';

const Message = ({ song, setFading,returnToHome }) => {
  const [inputValue, setInputValue] = useState('');
  const [prompt, setPrompt] = useState([])

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setPrompt(inputValue);
      // setState(false);
    }
  };


  return (
    <div className='text-[12px] md:text-[25px] text-shadow louize-display'  >
      <div className='text-center relative z-20 text-[18px] md:text-5xl  tracking-widest '> {song.name} </div>

        <Routes>
          <Route path="/" element={<Prompt prompt={prompt} song={song} setPrompt={setPrompt} />} />
          <Route path="/share" element={<SharePage returnToHome={returnToHome} setFading={setFading} setPrompt={setPrompt} prompt={prompt} song={song} />} />
        </Routes>


    </div>
  );
};

export default Message;
