import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useRef, useState, useEffect } from 'react';
import WebPlayback from './WebPlayback';

import SpotifyLogin from './spotify/login';
import Login from './Login';
import Home from './pages/Home';
import Menu from './pages/Menu';
import PlayScreen from './pages/PlayScreen';
import './App.css';

function App() {
  const [token, setToken] = useState('');
  const [appIntro, setAppIntro] = useState(true);

  const [songItem, setSongItem] = useState(null);
  const [endSong, setEndSong] = useState(false)
  const [paused, setPaused] = useState(false)
  const [toggle, togglePlay] = useState(false)
  const [playPause, setPlayPause] = useState(false)


  const [isFading, setFading] = useState(false);
  
  // const [controls, setControls] = useState(false);
  const fadeTimeoutRef = useRef(null);  // Add this line
  
  const player = { songItem,setSongItem,endSong,setEndSong,paused,setPaused,toggle,togglePlay, playPause,setPlayPause};


  // const handleSetVideo = (video) => {
  //   if (fadeTimeoutRef.current) {
  //     clearTimeout(fadeTimeoutRef.current);  // Clear the previous timeout
  //   }
  //   setFading(true);
  //   fadeTimeoutRef.current = setTimeout(() => {
  //     setVideo(video)
  //     if (video === null) {
  //       setFading(false);
  //     }
  //   }, 2000);
  // }

  useEffect(() => {
    async function getToken() {
      const loginToken = SpotifyLogin.getToken();

      if (!loginToken) {

      } else {
        setToken(loginToken);
      }
    }

    getToken();
  }, []);

  useEffect(() => {


    if (token !== '') {
      setTimeout(() => {
        setAppIntro(false);
      }, 4000);
    }
  }, [token]);

  return (<>
          <div className={`App ${!appIntro ? 'inactive' : ''} `}>
        <header className="App-header">
          <div className='login-contents'>
            <div className="anna-title">Intentions</div>
            {token === '' ? (<Login />) : (<></>)}
          </div>
        </header>
        </div>
 {token !== '' && (
<>
  <div className={`h-screen fixed w-full fade ${!appIntro ? 'active': ''}`}>
  <div className={`fixed w-full h-full pointer-events-none z-10 fade bg-black ${isFading ? 'fade-in' : 'fade-out'}`} />
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/menu" element={<Menu setFading={setFading} />} />
      {/* <Route path="/home" element={<Home />} /> */}
      <Route path="/song/:songId/*" element={<PlayScreen isFading={isFading} player={player} setFading={setFading} token={token} />} />
      {/* <Route path="/share" element={<SharePage />} /> */}
    </Routes>
  </Router>
  </div>
  
<WebPlayback songItem={songItem} {...player} token={token} />
  

  </>
  )}
    </>
  );

}

export default App;
