import React, { useRef, useEffect, useState } from "react";
import "./FullscreenVideoPlayer.css";

const FullscreenVideoPlayer = ({ src, finishedLoading }) => {
  const videoRef = useRef(null);
  const [prevSrc, setPrevSrc] = useState(null);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        videoRef.current.pause();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const handleDoubleClick = () => {
    if (videoRef.current) {
      videoRef.current.requestFullscreen();
    }
  };

  const handleCanPlayThrough = () => {
    if (src !== prevSrc) {
      setPrevSrc(src);
      finishedLoading();
      videoRef.current.play();
    }
  };

  return (
    <video
      ref={videoRef}
      src={src}
      className="video-player fixed min-w-full min-h-full"
      autoPlay
      muted
      playsInline
      onClick={handleVideoClick}
      onDoubleClick={handleDoubleClick}
      onCanPlayThrough={handleCanPlayThrough}
      loop={true}
      controls={false}
      disableRemotePlayback
    />
  );
};

export default FullscreenVideoPlayer;
