import React, { useState, useEffect } from 'react';
import Button from './components/Button';
import { Link } from 'react-router-dom';

const Prompt = ({ song, prompt, setPrompt }) => {
  const [inputValue, setInputValue] = useState('');
  const [showIntention, setShowIntention] = useState(prompt.length > 0);
  const [firstView, setFirstView] = useState(false);


  const handleIntention = () => {

    setPrompt([inputValue]);
    setFirstView(true);
    setTimeout(() => {
      setShowIntention(true);
    }, 1000);
  };


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleIntention();
    }
  };

  return (


    <>
      <div className={`whitespace-pre-line ${song.songClass}  mx-auto md:w-full	 text-left mt-5 md:mt-10  `}>
        {song.copy.map((item, index) => {
          return (<p key={`copy-${index}`} className={item.type}>{item.message}</p>)
        })}

      </div>


      {!showIntention ? (
        <div className={`fade ${prompt.length > 0 ? '' : 'active'}`}>
          <div className='mt-5 md:mt-10 text-center md:text-left louize-bold'>Write your own intention here...</div>
          <input
            type="text"
            className="custom-input  mt-5 md:mt-10 w-full louize-bold"
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder='My intention is to....'

          />
          <div className='mt-2 border-b-2' />

          <Button className={`rounded-btn border-0 align-centre ${inputValue === '' ? 'disabled' : ''}`} onClick={() => {
            if (inputValue !== '') {
              handleIntention()
            }
          }} >
            Enter
          </Button>
        </div>
      ) :
        <div className={`mt-5 text-center md:text-left  ${firstView && 'wipe'} h-[184px]`}

        >My Intention is to {prompt[0] === '.' ? `${prompt[0].trim()}` : `${prompt[0].trim()}.`}


          <Link to={`/song/${song.spotifyURI}/share`}>
            <Button className={`xl mt-10 mx-auto`}>
                See your artwork
            </Button>
          </Link>


        </div>

      }


    </>
  );
};

export default Prompt;
