import React from 'react';
import Logo from '../assets/anna-logo.png'
import { Link } from 'react-router-dom';
import Button from '../components/Button';

function Home() {
  return (
    <div className='h-full overflow-x-hidden	tracking-wide w-full bg-[#000000b3] flex md:items-start justify-center h-screen'>
      <div className='py-20 pb-40 h-min'>
        <div className='text-center max-w-[90vw] lg:max-w-5xl mx-auto louize-display '>
          <img draggable="false" className='w-32 mx-auto' src={Logo} />
          <div className='mx-auto text-3xl tracking-[10px]  md:text-6xl md:tracking-[20px] mt-5'>INTENTIONS </div>
          <div className='text-[12px] md:text-[25px] text-left mt-10'> Welcome to INTENTIONS <br/><br/>

Each track has been created with a specific purpose in mind. Follow these steps to experience the intentions behind each piece:<br/><br/>
1. Choose a track that speaks to you. <br/>
2. Read the accompanying message to understand the purpose behind the music.<br/>
3. Listen to the track with an open heart and mind.<br/>
4. Reflect on the intended purpose behind the music and even add your own if you feel moved to do so. <br/>
5. If you choose to add your own intention, you're invited to download and share your intention as the track concludes.<br/><br/>

I encourage you to take your time with each track, and allow yourself to be fully present in the moment.
Let the frequencies move through you and see where the music takes you. </div>
        </div>

          <Link to={`/menu`}>
        <Button className={'mx-auto'}>
          Enter
        </Button>
          </Link>
      </div>
    </div>
  );
}

export default Home;
